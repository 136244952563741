@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 40px;
    height: 40px;
    left: 50%;
    margin-left: -20px;
  }
}

.vertical-timeline-element-subtitle {
  font-weight: normal;
  color: grey;
}
.vertical-timeline-content {
  font-weight: normal;
  white-space: pre-line;
}
