.skills_logo {
  transition: ease-in-out 500ms;
}
.skills_logo:hover {
  transform: scale(1.1);
}

.skills_container {
  @media (min-width: 720px) {
    height: calc(100% - 3.125rem);
  }

  transition: ease-in-out 500ms;
}

.skills_container:hover {
  transform: scale(0.9);
}
