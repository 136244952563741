* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html {
  scroll-behavior: smooth;
}
body {
  /* background-color: #1b1c1b !important; */
  /* color: #dbdfdf !important; */
}

header {
  /* background: #3c3c3c; */
  padding: 1.5rem;
}

main {
  padding: 1.5rem;
}

a,
a:active,
a:visited {
  color: rgba(0, 0, 0, 0.65);
}
